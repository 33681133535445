// Define a mixin that accepts a theme and outputs the theme-specific styles.
@use '@angular/material' as mat;

// Define a custom typography config that overrides the font-family
/* stylelint-disable max-line-length */
$savviva-typography: mat.define-legacy-typography-config(
	$font-family: 'Roboto, sans-serif',
	$display-1: mat.define-typography-level(34px, 40px, 400),
	$title: mat.define-typography-level(20px, 20px, 500),
	$headline: mat.define-typography-level(24px, 32px, 400),
	$body-1: mat.define-typography-level(16px, 18.75px, 400),
	$caption: mat.define-typography-level(14px, 16px, 400),
	$button: mat.define-typography-level(12px, 14px, 500),
	$input: mat.define-typography-level(inherit, 1.125, 400)
);

/* These palettes are generated using https://themes.angular-material.dev/ */

$primary-palette: (
	50 : #e5e5e5,
	100 : #bdbebf,
	200 : #919394,
	300 : #656769,
	400 : #444748,
	500 : #232628,
	600 : #1f2224,
	700 : #1a1c1e,
	800 : #151718,
	900 : #0c0d0f,
	A100 : #68eef6,
	A200 : #38e8f3,
	A400 : #00eaf8,
	A700 : #00d2df,
	contrast: (
		50 : #000,
		100 : #000,
		200 : #000,
		300 : #fff,
		400 : #fff,
		500 : #fff,
		600 : #fff,
		700 : #fff,
		800 : #fff,
		900 : #fff,
		A100 : #000,
		A200 : #000,
		A400 : #000,
		A700 : #000,
	)
);

$warn-palette: (
	50 : #f7e4e4,
	100 : #eababa,
	200 : #dd8d8d,
	300 : #cf5f5f,
	400 : #c43c3c,
	500 : #ba1a1a,
	600 : #b31717,
	700 : #ab1313,
	800 : #a30f0f,
	900 : #940808,
	A100 : #ffd5dd,
	A200 : #ffa2b4,
	A400 : #ff6f8a,
	A700 : #ff5576,
	contrast: (
		50 : #000,
		100 : #000,
		200 : #000,
		300 : #000,
		400 : #fff,
		500 : #fff,
		600 : #fff,
		700 : #fff,
		800 : #fff,
		900 : #fff,
		A100 : #000,
		A200 : #000,
		A400 : #000,
		A700 : #000,
	)
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$savviva-primary: mat.define-palette($primary-palette);

$savviva-accent: mat.define-palette($primary-palette);

// The warn palette is optional (defaults to red).
$savviva-warn: mat.define-palette($warn-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$savviva-theme: mat.define-light-theme(
	(
		color: (
			primary: $savviva-primary,
			accent: $savviva-accent,
			warn: $savviva-warn,
		),
		typography: mat.define-typography-config(),
		density: 0,
	)
);
