@use '@angular/material' as mat;

@import 'theme';
@import 'shared';
@import 'scrollbar';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
@include mat.all-legacy-component-typographies($savviva-typography);
@include mat.legacy-core();
@include mat.all-legacy-component-themes($savviva-theme);
@include mat.all-component-themes($savviva-theme);

@import 'overrides/mat-form-field';
@import 'overrides/mat-radio-button';
@import 'overrides/mat-cdk-overlay';

.selected-box {
	border: solid 2px $primary-color;
}

.cdk-custom-placeholder {
	background: $white-color;
	border: dashed 2px $primary-color;
	border-radius: 1px;
	min-height: 60px;
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
	background: $white-color;
	opacity: 1;
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.cdk-overlay-container .cdk-overlay-pane .client-select {
	transform: translateY(40px)!important; // height of select component
}
