.cdk-global-overlay-wrapper {
	display: flex;
	position: absolute;
	z-index: 1000;
	overflow: auto;
	pointer-events: auto;
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.72);
}
