::-webkit-scrollbar {
	width: 0;
}

::-webkit-scrollbar:horizontal {
	height: 12px;
}

::-webkit-scrollbar-thumb {
	background: #ccc;
	border: 3px solid transparent;
	border-radius: 6px;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background: #aaa;
	border: 2px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-track:hover {
	border: 1px solid #e5e5e4;
}
