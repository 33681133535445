@import 'colors';
@import 'typography';

html,
body {
	margin: 0;
	height: 100%;
	font-weight: $font-weight-normal;
	font-size: $body-size;
	font-family: $body-font-family;
	color: $primary-dark-color;
}

a {
	color: $links-color;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0 !important;
}

.mat-drawer-container {
	background: $white-color !important;
	position: unset !important;
}

.mat-form-field-infix {
	background: $white-color !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
	background: $white-color !important;
}

.mat-dialog-container {
	padding: 0 !important;
	overflow: inherit !important;
	border-radius: 8px;
}

.board-container,
.dialog-container {
	display: flex;
	flex-direction: column;

	&__header {
		font-size: $header-bold-size;
		font-weight: $font-weight-bold;
		display: flex;
		justify-content: space-between;
		margin: 29px 23px 26px;
	}

	.title-container {
		display: flex;

		&__title {
			font-size: $header-bold-size;
			font-weight: $font-weight-bold;
			line-height: 40px;
			padding-right: 30px;

			.label-primary {
				color: $primary-color;
			}
		}

		&__button {
			display: flex;
			align-items: center;
			color: $primary-color;
			font-size: $body-size;
			font-weight: $font-weight-bold;
			cursor: pointer;
		}
	}

	.actions-container {
		position: relative;
		color: $primary-color;
		display: flex;

		&__button {
			cursor: pointer;
		}

		&__search {
			position: relative;

			.mat-form-field-prefix .mat-icon,
			.mat-form-field-suffix .mat-icon {
				font-size: inherit !important;
				line-height: 1 !important;
			}

			.mat-form-field-prefix .mat-icon {
				position: relative;
				top: 6px;
			}

			&_toggle {
				bottom: -22px;
				width: 0;
				right: 0;
				position: absolute;
				z-index: 11;
				transition: all 250ms ease-in-out;
				background: $white-color;
				overflow: hidden;

				&.active {
					display: flex;
					width: 30vw;
				}
			}
		}
	}
}

// issue: snackbar overlay block user and appears over the whole UI
// solution: reduce overlay size till the snackbar size itself and locate it at the bottom right position
//			 styles applied only to container of the snackbar - there are cases with these container classes
// 			 for other components (modals, dialogs, context menu etc.)
.cdk-overlay-container:has(.mat-snack-bar-container),
.cdk-global-overlay-wrapper:has(.mat-snack-bar-container) {
	bottom: 0 !important;
	right: 0 !important;
	top: auto !important;
	left: auto !important;
	height: auto !important;
	width: auto !important;
}
