$primary-color: #232628;
$secondary-color: rgba(#232628, 0.54);
$bubbles-color: #e5e5e5;
$primary-dark-color: #222;
$secondary-dark-color: #888;
$grey-background-color: #f8f8f8;
$notification-color: #ef492e;
$error-color: #fc8276;
$active-color: #1fc950;
$pending-color: #fdb12b;
$white-color: #fff;
$links-color: #b4b4b4;
$dots-color: #e6e2ff;
$icons-color: #87849a;
$table-row-hover-color: #eef3f5;
$gray-200: #eee;
