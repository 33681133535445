$body-font-family: 'Roboto', sans-serif;

$display-size: 34px;
$header-bold-size: 20px;
$header-size: 24px;
$body-bold-size: 16px;
$body-size: 16px;
$caption-size: 14px;
$caption-bold-size: 14px;
$tips-size: 12px;
$tips-bold-size: 12px;
$button-bold-size: 14px;
$button-size: 12px;

$font-weight-bold: 500;
$font-weight-normal: 400;

$display-line-height: 40px;
$header-bold-line-height: 18.75px;
$header-line-height: 18.75px;
$body-bold-line-height: 16px;
$body-line-height: 16px;
$caption-line-height: 16px;
$caption-bold-line-height: 16px;
$tips-line-height: 14px;
$tips-bold-line-height: 14px;
$button-bold-line-height: 16px;
$button-line-height: 14px;
